export const ABExperiments = {
  Homepage: {
    experiment_id: 'fdr-homepage-slider-cta-test',
    experiment_name: 'FDR: Homepage slider cta test',
  },
  HomepageRedesign: {
    experiment_id: 'homepage-redesign-multi-variate-test',
    experiment_name: 'FDR: Homepage Redesign Multi-variate Test',
  },
  article: {
    experiment_id: 'fdr-article-slider-banner-test',
    experiment_name: 'FDR: Article slider banner test',
  },
  dataVisualization: {
    experiment_id: 'fdr-home-data-visualization-test',
    experiment_name: 'FDR: Home page data visualization test',
  },
  homeHeroCta: {
    experiment_id: 'fdr-homepage-hero-cta',
    experiment_name: 'FDR: Home page hero cta',
  },
  clientLogin: {
    experiment_id: 'fdr-client-login-cta-on-mobile-top-nav',
    experiment_name: 'FDR: Client login cta on mobile top nav',
  },
  homePersonalization: {
    experiment_id: 'fdr_pzn_test',
    experiment_name: 'FDR Personalized Home Page',
  },
  ArticlesAndBlogsBottomCTA: {
    experiment_id: 'fdr-articles-and-blogs-bottom-cta',
    experiment_name: 'FDR Articles and Blog Bottom CTA',
  },
  trustStrips: {
    experiment_id: 'trust-strips',
    experiment_name: 'FDR: Trust Strips',
  },
  fdrCfosDisclosures: {
    experiment_id: 'fdr-cfos-disclosures',
    experiment_name: 'FDR: Cfos Disclosures',
  },
  homepageDropDownFloatingFooter: {
    experiment_id: 'homepage-drop-down-floating-footer',
    experiment_name: 'FDR: Homepage drop down floating footer',
  },
  homepageTrustStrip: {
    experiment_id: 'homepage-trust-strip',
    experiment_name: 'FDR: Homepage Trust Strip',
  },
  bpTrustpilotAndCta: {
    experiment_id: 'bp-trustpilot-and-cta',
    experiment_name: 'FDR: BP Trustpilot and CTA',
  },
}

export const experimentSectionByVariation = {
  'fdr-client-login-cta-on-mobile-top-nav': 'clientLogin',
  'fdr-homepage-hero-cta': 'homeHeroCta',
  'homepage-redesign-multi-variate-test': 'HomepageRedesign',
  'fdr-home-data-visualization-test': 'dataVisualization',
  fdr_pzn_test: 'homePersonalization',
  'fdr-articles-and-blogs-bottom-cta': 'ArticlesAndBlogsBottomCTA',
  'trust-strips': 'trustStrips',
  'fdr-cfos-disclosures': 'fdrCfosDisclosures',
  'homepage-drop-down-floating-footer': 'homepageDropDownFloatingFooter',
  'homepage-trust-strip': 'homepageTrustStrip',
  'bp-trustpilot-and-cta': 'bpTrustpilotAndCta',
}

export const ABExperimentVariations = {
  'fdr-article-slider-banner-test': {
    default: {
      name: 'control',
      label: 'Control',
    },
    'test-cell': {
      name: 'test-cell',
      label: 'Test cell',
    },
  },
  'fdr-homepage-slider-cta-test': {
    default: {
      name: 'control',
      label: 'control',
    },
    tp_below_dc: {
      name: 'tp_below_dc',
      label: 'tp_below_dc',
    },
  },
  'homepage-redesign-multi-variate-test': {
    control: {
      name: 'control',
      label: 'Control',
    },
    'control-ny': {
      name: 'control_ny',
      label: 'Control NY',
    },
    'variant-2': {
      name: 'variant-2',
      label: 'Variant 2',
    },
    'variant-3': {
      name: 'variant-3',
      label: 'Variant 3',
    },
    'variant-4': {
      name: 'variant-4',
      label: 'Variant 4',
    },
    'variant-5': {
      name: 'variant-5',
      label: 'Variant 5',
    },
    'variant-6': {
      name: 'variant-6',
      label: 'Variant 6',
    },
    personalized: {
      name: 'personalized',
      label: 'Personalized',
    },
  },
  'fdr-home-data-visualization-test': {
    default: {
      name: 'control_with_data_visualization',
      label: 'Control with data visualization',
    },
    control: {
      name: 'control',
      label: 'Control',
    },
    control_with_data_visualization: {
      name: 'control_with_data_visualization',
      label: 'Control with data visualization',
    },
    control_with_bar_chart: {
      name: 'control_with_bar_chart',
      label: 'Control with bar chart',
    },
    hide_data_visualization: {
      name: 'hide_data_visualization',
      label: 'Hide data visualization',
    },
  },
  'fdr-client-login-cta-on-mobile-top-nav': {
    default: {
      name: 'control',
      label: 'Control',
    },
    client_login: {
      name: 'client_login',
      label: 'Client login',
    },
  },
  'fdr-homepage-hero-cta': {
    control: {
      name: 'control',
      label: 'Control',
    },
    floating_menu: {
      name: 'floating_menu',
      label: 'Floating Menu',
    },
    get_a_free_debt_assessment: {
      name: 'get_a_free_debt_assessment',
      label: 'Get a free debt assessment',
    },
  },
  fdr_pzn_test: {
    control: {
      name: 'control',
      label: 'Control',
    },
    'variant-2': {
      name: 'variant-2',
      label: 'Variant 2',
    },
    'variant-3': {
      name: 'variant-3',
      label: 'Variant 3',
    },
    'variant-4': {
      name: 'variant-4',
      label: 'Variant 4',
    },
  },
  [ABExperiments.ArticlesAndBlogsBottomCTA.experiment_id]: {
    control: {
      name: 'control',
      label: 'Control',
    },
    'slider-a': {
      name: 'slider-a',
      label: 'Slider with 3 bullets CTA',
    },
  },
  [ABExperiments.trustStrips.experiment_id]: {
    control: {
      name: 'control',
      label: 'Control',
    },
    badges: {
      name: 'badges',
      label: 'Badges',
    },
    ratings: {
      name: 'ratings',
      label: 'Ratings',
    },
  },
  [ABExperiments.fdrCfosDisclosures.experiment_id]: {
    control: {
      name: 'control',
      label: 'Control',
    },
    off: {
      name: 'off',
      label: 'Off',
    },
  },
  [ABExperiments.homepageDropDownFloatingFooter.experiment_id]: {
    control: {
      name: 'control',
      label: 'Control',
    },
    test: {
      name: 'test',
      label: 'Test',
    },
  },
  [ABExperiments.homepageTrustStrip.experiment_id]: {
    control: {
      name: 'control',
      label: 'Control',
    },
    badges: {
      name: 'badges',
      label: 'Badges',
    },
    ratings: {
      name: 'ratings',
      label: 'Ratings',
    },
  },
  [ABExperiments.bpTrustpilotAndCta.experiment_id]: {
    control: {
      name: 'control',
      label: 'Control',
    },
    'variation-a': {
      name: 'variation-a',
      label: 'Variation A',
    },
  },
}
